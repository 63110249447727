import React from 'react'
import { cn } from '../../lib/utils'

interface Props {
  skipToId: string
  text?: string
}

const SkipToContentLink = ({
  skipToId,
  text = 'Skip to content',
  ...props
}: Props): JSX.Element => {
  return (
    <a
      {...props}
      href={`#${skipToId}`}
      className={cn(
        'text-text-primary focus:bg-surface-tertiary absolute left-1/2 -translate-y-full p-4 transition-transform duration-300',
        'focus:fixed focus:left-[35%] focus:top-1 focus:z-[1001] focus:h-auto focus:w-auto focus:translate-y-0',
        'focus:rounded focus:border focus:border-solid focus:no-underline'
      )}
    >
      {text}
    </a>
  )
}

export default SkipToContentLink
