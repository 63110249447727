import React, { useMemo } from 'react'
import { ApolloProvider as ApolloApolloProvider } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { useSessionId } from '@scentregroup/shared/session-id'
import { getAnonymousId } from '@scentregroup/shared/lib/segment'
import { makeApolloClient } from '../lib/apolloClient'

export const ApolloProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const auth0 = useAuth0()
  const { getOrGenerateSessionId } = useSessionId()

  const apollo = useMemo(
    () =>
      makeApolloClient({
        auth0,
        getSessionId: getOrGenerateSessionId,
        getAnonymousId,
      }),
    [auth0, getOrGenerateSessionId]
  )
  return <ApolloApolloProvider client={apollo}>{children}</ApolloApolloProvider>
}
