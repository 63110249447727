// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"website-client@45560"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/website";import * as Sentry from '@sentry/nextjs'
import { isSupportedBrowser } from '@scentregroup/shared/helpers/supported-browsers'

declare global {
  interface Navigator {
    userAgentData?: unknown
  }
}

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENV = process.env.NEXT_PUBLIC_SENTRY_ENV
const sentryIsProduction = SENTRY_ENV == 'prd'
const sentryTracesSampleRate = sentryIsProduction ? 0.001 : 0.1

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV,
  // List of integrations included by default
  // https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/integrations/#browser-integrations
  integrations: [Sentry.extraErrorDataIntegration()],
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/api\.([^.]+\.)*scentregroup\.io\//,
    /^\//,
  ],
  tracesSampleRate: sentryTracesSampleRate,
  ignoreErrors: [/Non-Error (promise|exception)/],
  beforeSend: (event: Sentry.ErrorEvent) => {
    // Do not send events for unsupported browsers

    // Detect user agent client hints - only very new browsers have this
    const userAgentData = navigator.userAgentData
    if (typeof userAgentData === 'object' && userAgentData !== null) {
      return event
    }

    // Otherwise, just sniff the user agent string like the good old days
    const headers = event?.request?.headers
    const userAgent = headers && headers['User-Agent']
    return isSupportedBrowser(userAgent) ? event : null
  },
  beforeBreadcrumb: (
    breadcrumb: Sentry.Breadcrumb,
    hint?: Sentry.BreadcrumbHint | undefined
  ) => {
    // Do not send breadcrumbs for unsupported browsers

    // Detect user agent client hints - only very new browsers have this
    const userAgentData = navigator.userAgentData
    if (typeof userAgentData === 'object' && userAgentData !== null) {
      return breadcrumb
    }

    // Otherwise, just sniff the user agent string like the good old days
    const headers = hint?.event?.request?.headers
    const userAgent = headers && headers['User-Agent']
    return isSupportedBrowser(userAgent) ? breadcrumb : null
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})

Sentry.setTag('app', 'website')
